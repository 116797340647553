.mat-mdc-raised-button.mat-primary,
.mat-mdc-raised-button.mat-accent,
.mat-mdc-raised-button.mat-warn,
.mat-mdc-unelevated-button.mat-primary,
.mat-mdc-unelevated-button.mat-accent,
.mat-mdc-unelevated-button.mat-warn,
.mdc-fab.mat-primary,
.mdc-fab.mat-accent,
.mdc-fab.mat-warn {
  color: white;
}

.mat-mdc-outlined-button[disabled], .mat-mdc-outlined-button.mat-mdc-button-disabled {
  cursor: default;
  pointer-events: none;
  color: var(--mdc-outlined-button-disabled-label-text-color) !important;
  border-color: var(--mdc-outlined-button-disabled-outline-color) !important;
}

.mat-mdc-outlined-button:not(:disabled) {
  color: var(--mdc-outlined-button-label-text-color, var(--mat-app-primary));
  border-color: var(--mdc-outlined-button-label-text-color, var(--mat-app-primary));
}

.mat-mdc-raised-button {
  box-shadow: $cardshadow;
}

.mat-mdc-fab,
.mat-mdc-mini-fab,
.mat-mdc-fab:hover,
.mat-mdc-fab:focus,
.mat-mdc-mini-fab:hover,
.mat-mdc-mini-fab:focus {
  box-shadow: none;
}

.mat-button-toggle-button {
  font-size: 14px;
}

@media (min-width: 768px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
}

.flex-column {
  flex-direction: column;
}

.mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.2 !important;
}

.mat-mdc-mini-fab.mat-inherit {
  background-color: transparent !important;
}

.mat-button-toggle-group.mat-button-toggle-group-appearance-standard {
  @extend .rounded ;
}
.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: var(--mat-legacy-button-toggle-height);
}
.mat-button-toggle-appearance-standard.mat-button-toggle-checked , .mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-checked::after {
  @extend .bg-primary-200;
  @extend .color-primary-800;
}
.mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-checked::after {
  width: 12px;
  height: 4px;
}
#floating-button {
  position: fixed;
  bottom: 30px;
  //background-color: $accent;
  padding: 0;
  z-index: 99;
  body[dir="rtl"] & {
    left: 24px;
  }

  body[dir="ltr"] & {
    right: 24px;
  }
}

#floating-button[disabled]  {
  cursor: default;
  pointer-events: none;
  color: var(--mat-fab-disabled-state-foreground-color);
  background-color: var(--mat-fab-disabled-state-container-color);
}


body[dir="rtl"] .pos-ret-basket-btn {
  position: fixed;
  right: auto !important;
  left: 30px;
  bottom: 20px;
}

body[dir="ltr"] .pos-ret-basket-btn {
  position: fixed;
  left: auto !important;
  right: 30px;
  bottom: 20px;
}

#item_number_barcode {
  max-width: 250px;
  height: 36px;
  margin: 0 5px;
  border-radius: 15px;
  background: #fff;
  color: #000;
  border: 0;
  box-shadow: 0 0 2px 2px rgb(255 255 255 / 47%);
  font-size: 15px;
  text-align: center;
}

