@import url("https://fonts.googleapis.com/css2?family=Cairo:slnt,wght@-11..11,200..1000&display=swap");


// font
$font-family: "Cairo", sans-serif;

// light color variable
$sidebarbg: white;
$background: white;
$cardbg: white;
$toolbar: white;
$cardshadow: rgba(60, 64, 67, 0.3) 0px 1px 1px 0px, rgba(60, 64, 67, 0.15) 0px 1px 6px 2px;
$mainshadow:rgba(0, 0, 0, 0.12) 0px -1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
$baseshadow:rgba(60, 64, 67, 0.3) 0px 1px 1px 0px, rgba(60, 64, 67, 0.15) 0px 1px 6px 2px;
$hoverBgColor: #f6f9fc;


// Dark color variable
$darksidebarbg: #1a2537;
$darkbackground: #1f2a3d;
$darkcardbg: #1a2537;
$darktoolbar: #1a2537;
$darkcardshadow: rgba(209, 209, 215, 0.3) 0px 0px 2px 0px,
rgba(145, 158, 171, 0.02) 0px 12px 24px -4px;
$darkborderColor: #333f55;
$darkformborderColor: #465670;
$darkhoverbgcolor: #333f55;
$darkthemelightprimary: #253662;
$darkthemelightaccent: #1c455d;
$darkthemelightwarning: #4d3a2a;
$darkthemelighterror: #4b313d;
$darkthemelightsuccess: #1b3c48;
$darktextPrimary: rgba(255, 255, 255, 0.85);
$darktextBody: rgba(255, 255, 255, 0.6);

// Sidenav
$sidenav-desktop: 280px !default;
$header-height: 70px !default;

//BorderColor
$borderColor: #e0e6eb;
$borderformColor: #e0e6eb;
$bordertableColor: #111c2d0d;

// custom
$primary: #6a1b9a;
$accent: #e44891;
$base : #039BE5;
$light-base : #f0f5ff;
$warning: #FF9800;
$error: #ff6692;
$success: #36c76c;
$white: #ffffff;
$black: #000000;
$dark: #29343d;
$shadow: #29343d;
$dark-200: #0a2540;

$light: #f2f6fa;
$muted: #526b7a;

$bordercheckboxColor: #bdc3cd;

$light-primary: #dddbff;
$light-accent: #a6f7f5;
$light-warning: #fff9e5;
$light-error: #ffccdb;
$light-success: #ebfaf0;

// layout
$boxedWidth: 1200px;
$outletHeight: calc(100vh - 110px);


$border-radius: 12px;

// text color
$textPrimary: #29343d;

$bodyColor: #526b7a;

$body-bg: #f4f7fb;

$gray-100: #7c8fac;
$gray-200: #f6f7f9;
$gray-300: #d4d4d4;
$gray-400: #b3b3b3;
$gray-500: #999999;
$gray-600: #7f7f7f;
$gray-700: #666666;
$gray-800: #4c4c4c;
$gray-900: #333333;

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family !important;
}

.mat-mdc-input-element::-webkit-calendar-picker-indicator {
  display: block !important;
}


.mat-mdc-slide-toggle {
  --mat-switch-disabled-selected-handle-opacity: .38 !important;
  --mat-switch-disabled-unselected-handle-opacity: .38 !important;
  --mat-switch-unselected-handle-size: 20px !important;
  --mat-switch-selected-handle-size: 20px !important;
  --mat-switch-pressed-handle-size: 20px !important;
  --mat-switch-with-icon-handle-size: 20px !important;
  --mat-switch-selected-handle-horizontal-margin: 0 !important;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0 !important;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0 !important;
  --mat-switch-unselected-handle-horizontal-margin: 0 !important;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0 !important;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0 !important;
  --mat-switch-visible-track-opacity: 1 !important;
  --mat-switch-hidden-track-opacity: 1 !important;
  --mat-switch-visible-track-transition: transform 75ms 0ms cubic-bezier(0, 0, .2, 1) !important;
  --mat-switch-hidden-track-transition: transform 75ms 0ms cubic-bezier(.4, 0, .6, 1) !important;
  --mat-switch-track-outline-width: 1px !important;
  --mat-switch-track-outline-color: transparent !important;
  --mat-switch-selected-track-outline-width: 1px !important;
  --mat-switch-selected-track-outline-color: transparent !important;
  --mat-switch-disabled-unselected-track-outline-width: 1px !important;
  --mat-switch-disabled-unselected-track-outline-color: transparent !important;
}


.mat-mdc-slide-toggle {
  --mat-switch-label-text-color: rgb(5, 67, 128) !important;
}

html {
  --mdc-switch-state-layer-size: 40px !important;

    --navbar-scroll: transparent;
    --navbar-scroll-text: white;
    --navbar-scroll-shadow: none;
    --navbar-scroll-btn-border: none;
    --navbar-scroll-btn-back: #8731d4;
    --gray2: #b2becd;
}

.mat-mdc-slide-toggle {
  --mat-switch-label-text-font: $font-family !important;
  --mat-switch-label-text-line-height: 22px !important;
  --mat-switch-label-text-size: 16px !important;
  --mat-switch-label-text-tracking: normal !important;
  --mat-switch-label-text-weight: 400 !important;
}

//.bg-primary,.mdc-switch:enabled .mdc-switch__track:after,.mdc-switch .mdc-switch__handle:before {
//  background: $primary !important
//}

html {
  --mdc-switch-disabled-selected-icon-opacity: .38!important;
  --mdc-switch-disabled-track-opacity: .12!important;
  --mdc-switch-disabled-unselected-icon-opacity: .38!important;
  --mdc-switch-handle-height: 20px!important;
  --mdc-switch-handle-shape: 10px !important;
  --mdc-switch-handle-width: 20px !important;
  --mdc-switch-selected-icon-size: 18px !important;
  --mdc-switch-track-height: 14px !important;
  --mdc-switch-track-shape: 7px !important;
  --mdc-switch-track-width: 36px !important;
  --mdc-switch-unselected-icon-size: 18px !important;
  --mdc-switch-selected-focus-state-layer-opacity: .12 !important;
  --mdc-switch-selected-hover-state-layer-opacity: .04 !important;
  --mdc-switch-selected-pressed-state-layer-opacity: .1 !important;
  --mdc-switch-unselected-focus-state-layer-opacity: .12 !important;
  --mdc-switch-unselected-hover-state-layer-opacity: .04 !important;
  --mdc-switch-unselected-pressed-state-layer-opacity: .1 !important;
  --mdc-dialog-container-shape: 4px !important;
  --mat-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, .2), 0px 24px 38px 3px rgba(0, 0, 0, .14), 0px 9px 46px 8px rgba(0, 0, 0, .12) !important;
  --mat-dialog-container-max-width: 80vw !important;
  --mat-dialog-container-small-max-width: 80vw !important;
  --mat-dialog-container-min-width: 0 !important;
  --mat-dialog-actions-alignment: center !important;
  --mat-dialog-actions-padding: 8px !important;
  --mat-dialog-content-padding: 20px 24px !important;
  --mat-dialog-with-actions-content-padding: 20px 24px !important;
  --mat-dialog-headline-padding: 0 24px 9px !important;

  --mdc-switch-disabled-unselected-handle-color: #424242 !important;
  --mdc-switch-disabled-unselected-icon-color: #fff !important;
  --mdc-switch-disabled-unselected-track-color: #424242 !important;
  --mdc-switch-handle-surface-color: #fff !important;
  --mdc-switch-selected-icon-color: #fff !important;
  --mdc-switch-unselected-focus-handle-color: #212121 !important;
  --mdc-switch-unselected-focus-state-layer-color: #424242 !important;
  --mdc-switch-unselected-focus-track-color: #e0e0e0 !important;
  --mdc-switch-unselected-handle-color: #616161 !important;
  --mdc-switch-unselected-hover-handle-color: #212121 !important;
  --mdc-switch-unselected-hover-state-layer-color: #424242 !important;
  --mdc-switch-unselected-hover-track-color: #e0e0e0!important;
  --mdc-switch-unselected-icon-color: #fff!important;
  --mdc-switch-unselected-pressed-handle-color: #212121!important;
  --mdc-switch-unselected-pressed-state-layer-color: #424242!important;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0!important;
  --mdc-switch-unselected-track-color: #e0e0e0!important;
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, .2), 0px 1px 1px 0px rgba(0, 0, 0, .14), 0px 1px 3px 0px rgba(0, 0, 0, .12)!important;
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, .2), 0px 0px 0px 0px rgba(0, 0, 0, .14), 0px 0px 0px 0px rgba(0, 0, 0, .12)!important;
  --mdc-switch-disabled-label-text-color: rgba(0, 0, 0, .38)!important;


  --mdc-dialog-container-color: white !important;
  --mdc-dialog-subhead-color: rgba(0, 0, 0, .87) !important;
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, .6) !important;
  --mdc-dialog-subhead-font: $font-family !important;
  --mdc-dialog-subhead-line-height: 26px !important;
  --mdc-dialog-subhead-size: 18px !important;
  --mdc-dialog-subhead-weight: 600 !important;
  --mdc-dialog-subhead-tracking: normal !important;
  --mdc-dialog-supporting-text-font: $font-family !important;
  --mdc-dialog-supporting-text-line-height: 20px !important;
  --mdc-dialog-supporting-text-size: 14px !important;
  --mdc-dialog-supporting-text-weight: 400 !important;
  --mdc-dialog-supporting-text-tracking: normal !important;
  --mat-form-field-container-height: 45px !important;
  --mat-sidenav-container-divider-color: #e0e6eb !important;
  --mat-standard-button-toggle-selected-state-background-color: #f2f6fa !important;
  --mat-standard-button-toggle-selected-state-text-color: #526b7a !important;
  --mat-table-row-item-label-text-size: 14px !important;
  --mat-expansion-container-text-size: 14px !important;
  .mat-mdc-button .mat-mdc-button-persistent-ripple::before {
    --mat-text-button-state-layer-color: #f2f6fa !important;
  }

  .mat-mdc-standard-chip {
    --mdc-chip-elevated-container-color: #f2f6fa !important;
    --mdc-chip-elevated-disabled-container-color: #f2f6fa !important;
    --mdc-chip-flat-disabled-selected-container-color: #f2f6fa !important;
  }

  .mat-mdc-form-field-infix {
    --mat-form-field-container-height: 45px;
    --mat-form-field-container-vertical-padding: 13px;
  }

  .mat-mdc-radio-button .mdc-radio .mdc-radio__background::before {
    --mat-radio-ripple-color: #f2f6fa !important;
  }

  .mdc-list-item:focus::before {
    --mdc-list-list-item-focus-state-layer-color: #f2f6fa !important;
  }
}

:root {
  --mat-divider-color: #e0e6eb;
  --mat-theme-primary-color: #6a1b9a;
  --mat-theme-accent-color: #e44891;
}

.mat-mdc-nav-list .mat-mdc-list-item {
  --mat-list-active-indicator-shape: 12px;
}

.mat-mdc-tooltip {
  --mdc-plain-tooltip-container-shape: 12px;
}

.mat-mdc-card {
  --mdc-elevated-card-container-shape: 12px;
}

.mdc-checkbox:hover .mdc-checkbox__ripple {
  --mdc-checkbox-unselected-hover-state-layer-opacity: 1;
  --mdc-checkbox-unselected-hover-state-layer-color: #f2f6fa;
}

.mdc-button {
  --mdc-filled-button-container-shape: 8px;
  --mdc-text-button-container-shape: 8px;
  --mdc-protected-button-container-shape: 8px;
  --mdc-outlined-button-container-shape: 8px;
  --mat-standard-button-toggle-shape: 8px;
}

.mdc-text-field {
  --mdc-outlined-text-field-container-shape: 8px;
  --mdc-outlined-text-field-outline-color: #e0e6eb;
  --mdc-outlined-text-field-input-text-color: #29343d;
}

.mat-mdc-menu-panel {
  --mat-menu-container-shape: 12px;
}

.mdc-list-item:hover::before {
  --mdc-list-list-item-hover-state-layer-opacity: 0.09;
}

.mat-mdc-mini-fab {
  --mat-fab-small-foreground-color: #29343d;
}

